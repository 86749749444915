import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          Meno - Make the right choice
          <nav>
            <Link to="/">Home</Link>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </nav>
        </header>
        <Routes>
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

function Home() {
  return (
    <div>
      <h1>Welcome to Meno</h1>
      <a href="/privacy-policy">Privacy Policy</a>
    </div>
  );
}

function PrivacyPolicy() {
  return (
    <div className="PrivacyPolicy">
      <header className="PrivacyPolicy-header">
        <h1>Privacy Policy</h1>
      </header>
      <div className="PrivacyPolicy-content">
        <p>
          At Meno, we do not collect, store, or process any personal data from our users. Our app does not track or gather any user information, ensuring complete privacy and anonymity.
        </p>
        <p>
          If you have any questions or concerns about our privacy practices, please contact us at info@menotechnologies.com.
        </p>
      </div>
    </div>
  );
}

function Footer() {
  return (
    <footer className="App-footer">
      <p>Support email: <a href="mailto:info@menotechnologies.com">info@menotechnologies.com</a></p>
    </footer>
  );
}

export default App;
